/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    function praticoFeaturedSlick(el){
        el.not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            fade: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        adaptiveHeight: true
                    }
                }
            ]
        });
    }

    function initArticleCarrousel(el){
        $(window).on('resize', function(e) {
            var resizeTimer;
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
                $(".pratico-featured-slider__slides").each(function(){
                    praticoFeaturedSlick($(this));
                });
            }, 250);
        });
        el.find('.pratico-featured-slider__slides').each(function(){
            praticoFeaturedSlick($(this));
        });
    }

    var Sage = {
        // All pages
        'common': {
            init: function() {},
            finalize: function() {}
        },
        'home': {
            init: function() {},
            finalize: function() {
                $('[fc-praticoFeatured-carrousel]').each(function(){
                    initArticleCarrousel($(this));
                });
            }
        },
        'page_template_template_category': {
            init: function() {},
            finalize: function() {
                if($('.paging-infinite-link a').length) {
                    $('.category-container').infiniteScroll({
                    path: '.paging-infinite-link a',
                    append: '.get-infinite',
                    history: false,
                    prefill: true,
                    });
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function(func, funcname, args) {
        var fire;
        var namespace = Sage;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';

        if (fire) {
          namespace[func][funcname](args);
        }
      },
      loadEvents: function() {
        // Fire common init JS
        UTIL.fire('common');

        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        });

        // Fire common finalize JS
        UTIL.fire('common', 'finalize');
      }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

  })(jQuery); // Fully reference jQuery after this point.
