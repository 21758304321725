/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0)
            return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

/*
   * jQuery Function Toggle Plugin
   * Copyright 2011, Felix Kling
   */

  ;(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else {
        // Browser globals
        factory(jQuery);
    }
  }) (function($) {
    $.fn.funcToggle = function(type, data) {
        var dname = "jqp_eventtoggle_" + type + (new Date()).getTime(),
            funcs = Array.prototype.slice.call(arguments, 2),
            numFuncs = funcs.length,
            empty = function() {},
            false_handler = function() {return false;};

        if(typeof type === "object") {
            for( var key in type) {
                $.fn.funcToggle.apply(this, [key].concat(type[key]));
            }
            return this;
        }
        if($.isFunction(data) || data === false) {
            funcs = [data].concat(funcs);
            numFuncs += 1;
            data = undefined;
        }

        funcs = $.map(funcs, function(func) {
            if(func === false) {
                return false_handler;
            }
            if(!$.isFunction(func)) {
                return empty;
            }
            return func;
        });

        this.data(dname, 0);
        this.bind(type, data, function(event) {
            var data = $(this).data(),
                index = data[dname];
            funcs[index].call(this, event);
            data[dname] = (index + 1) % numFuncs;
        });
        return this;
    };
  });

  (function($) {
    function updatePostRatingValues() {
        var count = $('span[data-vote-count]').attr('data-vote-count');
        if(count > 1){
            $('[data-vote-count]').text(count + ' votes');
        } else {
            $('[data-vote-count]').text(count + ' vote');
        }
        var average = $('span[data-vote-average]').attr('data-vote-average');
        if(average) {
            $('[data-vote-average]').html(average + '/5');
            $('[data-vote-average]').show();
        }
    }

    function tileArticleSlick(el){
        el.not('.slick-initialized').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:el.parent().find('.slick-prev'),
          nextArrow:el.parent().find('.slick-next'),
          dots: false,
          fade: false,
          infinite: true,
        });
    }

    function initArticleCarrousel(el){
        $(window).on('resize', function(e) {
            var resizeTimer;
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
                $(".other-articles-slider__slides").each(function(){
                tileArticleSlick($(this));
                });
            }, 250);
        });
        el.find('.other-articles-slider__slides').each(function(){
            tileArticleSlick($(this));
        });
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
      // All pages
      'common': {
        init: function() {},
        finalize: function() {
            $(".other_sites__close").on('click', function(){
                $(".other_sites__switch").trigger( "click" );
            });

            $(".other_sites__switch").funcToggle('click', function () {
                $("body").addClass('other_sites--open');
                $(".site__wrapper").css({
                    'transform': 'translateY('+ $('.other_sites__wrapper').outerHeight() +'px)',
                    '-webkit-transform': 'translateY('+ $('.other_sites__wrapper').outerHeight() +'px)'
                });
                },
                function () {
                $("body").removeClass('other_sites--open');
                $(".site__wrapper").css({
                    'transform': 'translateY(0)',
                    '-webkit-transform': 'translateY(0)'
                });
                }
            );

            $('[data-mobile-menu-toggle]').on('click', function(){
                $('body').toggleClass('mobile-menu-open');
            });
            $('.mobile-menu-open').on('click', function(){
                $('body').removeClass('mobile-menu-open');
            });

            $('.responsive-menu li.has-sub.active').each(function(){
                $(this).addClass('open');
                $(this).children('ul').slideDown();
                $(this).siblings('li').children('ul').slideUp();
                $(this).siblings('li').removeClass('open');
                $(this).siblings('li').find('li').removeClass('open');
                $(this).siblings('li').find('ul').slideUp();
            });

            $('.responsive-menu li.has-sub>a').on('click', function(){
                $(this).removeAttr('href');
                var element = $(this).parent('li');
                if (element.hasClass('open')) {
                element.removeClass('open');
                element.find('li').removeClass('open');
                element.find('ul').slideUp();
                }
                else {
                element.addClass('open');
                element.children('ul').slideDown();
                element.siblings('li').children('ul').slideUp();
                element.siblings('li').removeClass('open');
                element.siblings('li').find('li').removeClass('open');
                element.siblings('li').find('ul').slideUp();
                }
            });

            // Patch menu ipad/touch
            function openSubMenu() {
                $(this).find('.sub-menu').addClass("hovered");
            }
            function closeSubMenu() {
                $(this).find('.sub-menu').removeClass("hovered");
            }
            function toggleSubMenu() {
                $(this).find('.sub-menu').toggleClass("hovered").focus();
            }

            $('.header-main__menu').find('.menu-item-has-children').bind('mouseover', openSubMenu);
            $('.header-main__menu').find('.menu-item-has-children').bind('mouseout', closeSubMenu);
            $('.header-main__menu').find('.menu-item-has-children').bind('click', toggleSubMenu);



            $(".dinner-of-the-day-slider__slides").slick({
                lazyLoad: 'ondemand',
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow:$('.dinner-of-the-day-slider__arrows .slick-prev'),
                nextArrow:$('.dinner-of-the-day-slider__arrows .slick-next'),
                dots: false,
                fade: true
            })
            .on('lazyLoaded', function (event, slick) {
                slick.$slides.css('height', slick.$slideTrack.height() + 'px');
            });
            // $(".dinner-of-the-day-slider__slides").show();

            $('.dinner-of-the-day-slider__slides').slick('slickGoTo',$('.dinner-of-the-day-slider__top .day').attr('data-current'), true);

            $('.dinner-of-the-day-slider__slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $next = nextSlide;
                if($next > 6) {$next = 0;}
                $('.dinner-of-the-day-slider__top .day').html($.parseJSON($('.dinner-of-the-day-slider__top .day').attr('data-days'))[$next]);
            });

            // BEGIN Scroll Up arrow
            var controller = new ScrollMagic.Controller();
            new ScrollMagic.Scene({triggerElement: "body", triggerHook: 'onLeave', offset: 500})
                .setClassToggle(".scrollUp-wrapper", "is-pinned")
                .addTo(controller);

            $("[data-func-scrollUp]").on('click', function () {
                $("html, body").animate({scrollTop: 0}, 400);
            });
            // END Scroll Up arrow

            // Newsletter
            $('.fc-newsletterToggle').on('click', function(){
                $('.newsletter__wrapper').toggleClass('mobile-show');
            });

            $('.wpcf7-submit').on('click', function(){
                $(this).addClass("loading");
            });

            var disableLoadingAnim = function (event) {
                $(".newsletter-form__form .wpcf7-submit").removeClass("loading");
            };

            document.addEventListener('wpcf7invalid', disableLoadingAnim, false);
            document.addEventListener('wpcf7spam', disableLoadingAnim, false);
            document.addEventListener('wpcf7mailfailed', disableLoadingAnim, false);

            document.addEventListener( 'wpcf7mailsent', function( event ) {
                $(".newsletter-form__form .wpcf7-submit").removeClass("loading");
                $('.wpcf7-mail-sent-ok').fadeToggle(400);
            }, false );

            // $(".other-articles-slider__slides").slick({
            //     lazyLoad: 'ondemand',
            //     slidesToShow: 1,
            //     slidesToScroll: 1,
            //     prevArrow:$('.other-articles-slider__arrow.slick-prev'),
            //     nextArrow:$('.other-articles-slider__arrow.slick-next'),
            //     dots: false,
            //     fade: true
            // });

            $('.fc-article-carrousel').each(function(){
                initArticleCarrousel($(this));
            });
            $('.home-container').on( 'append.infiniteScroll', function( event, response, path, items ) {
                var arr = jQuery.makeArray( items );
                var i;
                for (i = 0; i < arr.length; ++i) {
                    initArticleCarrousel($(arr[i]).find('.fc-article-carrousel'));
                }
            });

            $('.category-container').on( 'append.infiniteScroll', function( event, response, path, items ) {
                var arr = jQuery.makeArray( items );
                var i;
                for (i = 0; i < arr.length; ++i) {
                    initArticleCarrousel($(arr[i]).find('.fc-article-carrousel'));
                }
            });
        }
      },
      // Home page
      'home': {
        init: function() {},
        finalize: function() {
            if($('.paging-infinite-link a').length) {
                $('.home-container').infiniteScroll({
                path: '.paging-infinite-link a',
                append: '.get-infinite',
                history: false,
                prefill: false,
                status: '.page-load-status'
                });
            }
        }
      },
      'search': {
        finalize: function() {
            if($('.paging-infinite-link a').length) {
                $('.category-container').infiniteScroll({
                path: '.posts-navigation a',
                append: '.get-infinite',
                history: false,
                prefill: true,
                });
            }
        }
      },
      'category': {
        finalize: function() {
            if($('.paging-infinite-link a').length) {
                $('.category-container').infiniteScroll({
                path: '.paging-infinite-link a',
                append: '.get-infinite',
                history: false,
                prefill: true,
                });
            }
        }
      },
      'single': {
        finalize: function() {
            $article_id = $('ul.article-rating__listing').attr('data-post_id');

            updatePostRatingValues();

            var cookie_arr = {};

            var post_vote_infos = getCookie("post_vote_infos");
            if(post_vote_infos){
                cookie_arr = JSON.parse(post_vote_infos);
                if(cookie_arr[$article_id]) {
                    $("[data-vote_value="+cookie_arr[$article_id]+"]").addClass('active');
                }
            }

            $(".best-recipes-slider__slides").slick({
                slidesToShow: 5,
                slidesToScroll: 5,
                prevArrow:$('.best-recipes-slider__arrows .slick-prev'),
                nextArrow:$('.best-recipes-slider__arrows .slick-next'),
                dots: false,
                fade: false,
                infinite: false,
                responsive: [
                {
                    breakpoint: 992,
                    settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                    }
                }
                ]
            });

            $("[data-add-like-recipe]").click(function(){
                // if ($(this).hasClass("must-be-logged")) {
                //     $('#loginModal').modal('show');
                // } else if (!$(this).hasClass("active")) {
                //     var post_id = $(this).data("post_id");
                //     jQuery.ajax({
                //         type: "post",
                //         url: ajax_var.url,
                //         data: "action=post-like&nonce="+ajax_var.nonce+"&post_like=&post_id="+post_id,
                //         success: function(count){
                //             if(count == 'pop-login'){
                //                 $('#loginModal').modal('show');
                //                 return;
                //             }
                //             if(count !== "already"){
                //                 $('.recipe__heart--button').addClass("active");
                //                 $('.recipe__heart--shadow').addClass('play');
                //                 $('.recipe__heart--button .count').text(count);
                //             }
                //         },
                //         error: function (count) {
                //             $(this).find(".count").text(count);
                //             $(this).removeClass("active");
                //             $('.recipe__heart--shadow').removeClass('play');
                //         }
                //     });
                // } else if ($(this).hasClass("active") && !$(this).hasClass("must-be-logged")) {
                //     var post_id = $(this).data("post_id");
                //     jQuery.ajax({
                //         type: "post",
                //         url: ajax_var.url,
                //         data: "action=post-unlike&nonce="+ajax_var.nonce+"&post_unlike=&post_id="+post_id,
                //         success: function(count){
                //             $('.recipe__heart--button').removeClass("active");
                //             $('.recipe__heart--shadow').removeClass('play');
                //             $('.recipe__heart--button .count').text(count);
                //         }
                //     });
                // }
                // return false;
            });

            $("[data-vote_value]").click(function(){
                var post_id = $(this).parent().data("post_id");
                var vote_value = $(this).data("vote_value");

                if(cookie_arr[post_id]) {
                    total = $('.article-rating__listing').attr('data-vote-total');
                    total = total - cookie_arr[post_id] + vote_value;
                    $('.article-rating__listing').attr('data-vote-total',total);
                    average = total / $('[data-vote-count]').attr('data-vote-count');
                    average = Math.ceil(average*10)/10;
                    $('[data-vote-average]').html(average + '/5');
                } else {
                    total = parseInt($('.article-rating__listing').attr('data-vote-total')) + vote_value;
                    $('.article-rating__listing').attr('data-vote-total',total);
                    num = parseInt($('[data-vote-count]').attr('data-vote-count')) + 1;
                    $('[data-vote-count]').attr('data-vote-count',num);
                    average = total / num;
                    average = Math.ceil(average*10)/10;
                    $('[data-vote-average]').html(average + '/5');
                    if(num <= 1) {
                        $('[data-vote-count]').html(num + " vote");
                    } else {
                        $('[data-vote-count]').html(num + " votes");
                    }
                }

                cookie_arr[post_id] = vote_value;
                setCookie('post_vote_infos',JSON.stringify(cookie_arr),5000);

                $('.article-rating__single').each(function(index){
                    $(this).removeClass('active');
                });
                $("[data-vote_value="+vote_value+"]").addClass('active');
                if ($(this).hasClass("active")) {
                    jQuery.ajax({
                        type: "post",
                        url: ajax_var.url,
                        data: "action=post-grade&nonce="+ajax_var.nonce+"&vote_value="+vote_value+"&post_id="+post_id,
                        success: function(count){
                            $('.recipe__heart--button').removeClass("active");
                            $('.recipe__heart--shadow').removeClass('play');
                            $('.recipe__heart--button .count').text(count);}
                    });
                }
                return false;
            });

          //Print article
          $('.article-share__single.dropdown-toggle').on('click', function(){
            $(this).find('.article-print-dropdown').toggleClass('open');
          });

          $('[data-fc-print]').on('click', function(){
            if($(this).data('print-image') === false ){
              $('body').addClass('print-no-img');
            } else {
              $('body').removeClass('print-no-img');
            }
            window.onafterprint = function(e){
              $(window).off('mousemove', window.onafterprint);
              $('body').removeClass('print-no-img');
              $('.article-share__single.dropdown-toggle').find('.article-print-dropdown').removeClass('.open');
            };
            window.print();
            setTimeout(function(){
                $(window).one('mousemove', window.onafterprint);
            }, 1);
          });

          $('.post-ratings a').on('click', function(){
            $('#loginModal').modal('show');
          });

          if($('.wppb-error').length){
            $('#loginModal').modal('show');
          }

          // Modal étapes article
          $('.js-zoom-image').each(function (index, el) {
              $(el).colorbox({
                  current: '',
                  rel: 'js-zoom-image',
                  title: $(el).attr('data-description') || el.title
              });
          });
        }
      }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function(func, funcname, args) {
        var fire;
        var namespace = Sage;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';

        if (fire) {
          namespace[func][funcname](args);
        }
      },
      loadEvents: function() {
        // Fire common init JS
        UTIL.fire('common');

        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        });

        // Fire common finalize JS
        UTIL.fire('common', 'finalize');
      }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

  })(jQuery); // Fully reference jQuery after this point.

jQuery(function($) {
    // Set cookie information
    //
    // @return Void
    function setGDPRCookie(cookie_name, value, expiration_days) {
        var expiration_date = new Date();
        expiration_date.setDate(expiration_date.getDate() + expiration_days);

        var cookie_value = escape(value) + ((expiration_days == null) ? "" : "; expires=" + expiration_date.toUTCString())+"; path=/";
        document.cookie = cookie_name + "=" + cookie_value;
    }

    // Get cookie information
    //
    // @return String || undefined
    function getGDPRCookie(cookie_name) {
        var cookie_value = document.cookie;
        var cookie_start = cookie_value.indexOf(" " + cookie_name + "=");

        if (cookie_start == -1) cookie_start = cookie_value.indexOf(cookie_name + "=");

        if (cookie_start == -1) {
        cookie_value = null;
        }else{
        cookie_start = cookie_value.indexOf("=", cookie_start) + 1;
        var c_end = cookie_value.indexOf(";", cookie_start);

        if (c_end == -1) c_end = cookie_value.length;

        cookie_value = unescape(cookie_value.substring(cookie_start,c_end));
        }

        return cookie_value;
    }

    // GPDR cookie popup
    // ----------
    var gpdr_cookie = getGDPRCookie("gdpr-agreement");

    //var gpdr_date_changed = new Date(2020, 12, 01);
    //var gpdr_max_date = gpdr_date_changed.setYear(gpdr_date_changed.getFullYear() + 1);
    //var gpdr_current_date = !Date.now ? 0 : Date.now();

    if(gpdr_cookie == null) {
        $(".gpdr-agreement").removeClass("gpdr-hide");

        $(".gpdr-agreement > a").bind("click", function(e) {
            e.preventDefault();
            $(".gpdr-agreement").addClass("gpdr-hide");
            setGDPRCookie("gdpr-agreement", 1, 360);
        });
    }
});
